import history from "history/browser";
import React, { useEffect, useRef, useState } from "react";
import useKeyboard, { ENTER, RIGHT_KEY, LEFT_KEY, UP_KEY, DOWN_KEY } from './use-keyboard';
import './Shoveler.css';
import './Grid.css';
import Spinner from "./Spinner";
import YouTubeVideo from "./YouTubeVideo";

function Grid({ videos, isSelected, onVideoVisibleChange,
  isVideoVisible, loadingMoreVideos, onFetchMoreVideos, onMenuSelect }) {

  const [videoIndex, setVideoIndex] = useState(0);
  const thumbnailHolderRef = useRef(null);
  const thumbnailsRef = useRef([]);

  const VIDEO_ROW_COUNT = 4;

  useEffect(() => {
    history.listen(({ location, action }) => {
      if (location.pathname === "/" && action === "POP") {
        onVideoVisibleChange(false);
      }
    });
  }, [onVideoVisibleChange]);

  useKeyboard(event => {
    if (!isSelected || isVideoVisible)
      return;

    let horizontalDirection = 0;
    if (event.keyCode === RIGHT_KEY || event.keyCode === LEFT_KEY) {
      if (event.keyCode === RIGHT_KEY) {
        horizontalDirection = 1; // Going right
      } else {
        horizontalDirection = -1; // Going left
      }
      if ((horizontalDirection > 0 && videoIndex >= videos.length - 1) ||
        (horizontalDirection < 0 && videoIndex <= 0)) {
        // Do nothing as we're going out of bounds
      } else if ((videoIndex + horizontalDirection) % VIDEO_ROW_COUNT === 0
        && horizontalDirection > 0) {
        // Do nothing as we're going right at the right-most edge of the grid
      } else if ((videoIndex + horizontalDirection) % VIDEO_ROW_COUNT === VIDEO_ROW_COUNT - 1
        && horizontalDirection < 0) {
        // Do nothing as we're going left at the left-most edge of the grid
      } else {
        setVideoIndex(videoIndex + horizontalDirection);
      }
    }

    let verticalDirection = 0;
    if (event.keyCode === UP_KEY || event.keyCode === DOWN_KEY) {
      if (event.keyCode === UP_KEY) {
        verticalDirection = -1; // Going up
      } else {
        verticalDirection = 1; // Going down
      }
      if (verticalDirection > 0 && (videoIndex >= videos.length - (videos.length % VIDEO_ROW_COUNT))) {
        // Do nothing as we're going out of bounds
      } else if (verticalDirection < 0 && videoIndex <= VIDEO_ROW_COUNT - 1) {
        onMenuSelect();
      } else {
        if (verticalDirection > 0) {
          thumbnailsRef.current[videoIndex].scrollIntoView({ block: "start", behavior: "smooth" });
        } else {
          thumbnailsRef.current[videoIndex].scrollIntoView({ block: "end", behavior: "smooth" });
        }

        if (videoIndex + (verticalDirection * VIDEO_ROW_COUNT) >= videos.length) {
          // Automatically move selection to the last video
          setVideoIndex(videos.length - 1);
        } else {
          setVideoIndex(videoIndex + (verticalDirection * VIDEO_ROW_COUNT));
        }
      }
    }

    if (event.keyCode === ENTER) {
      onVideoVisibleChange(true);
      history.push("/video");
    }

    // Check if more videos need to be loaded based on videoIndex
    if (videoIndex >= videos.length - 5) {
      onFetchMoreVideos();
    }
  }, "SLOW");

  return (
    <>
      <div className="channel" tabIndex="0">
        <div id="one-D-view-item-elements" className="one-D-details-container">
          <div id="one-d-no-items" className="one-d-no-items-container">No content found.</div>
          <div id="one-D-grid-container">
            <div id="channel-view" className="channel-row-container">
              {<div className="grid-thumbnail-holder"
                ref={thumbnailHolderRef}>
                {videos.map((video, index) =>
                  <div key={video.id}
                    className={"grid-rowitem-static"}
                    ref={element => thumbnailsRef.current[index] = element}>
                    <img className={"grid-full-img" +
                      (isSelected && index === videoIndex ? " selected" : "")}
                      src={`https://i.ytimg.com/vi/${video.id}/hqdefault.jpg`}
                      alt={video.title}
                    />
                    <p className="video-title">
                      {videos[index].title}
                    </p>
                  </div>
                )}
                {loadingMoreVideos &&
                  <div className="channel-spinner">
                    <Spinner />
                  </div>
                }
              </div>}
            </div>
          </div>
        </div>
      </div>
      {isVideoVisible && isSelected && <YouTubeVideo video={videos[videoIndex]} />}
    </>
  );
}

export default Grid;
