import React, { useCallback, useEffect, useState } from 'react';
import LoadingShoveler from "./LoadingShoveler";
import * as KidsApiService from './KidsApiService';
import Shoveler from "./Shoveler";
import Grid from "./Grid";
import Spinner from "./Spinner";
import useKeyboard, { UP_KEY } from './use-keyboard';
import "./Channel.css";

function Channel({ channel, isSelected, shouldLoad, isVideoVisible,
  onVideoVisibleChange, requiresTitle, format, onMenuSelect }) {

  const [continuationToken, setContinuationToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMoreVideos, setLoadingMoreVideos] = useState(false);
  const [videos, setVideos] = useState([]);

  const fetchChannelVideos = useCallback(async (channelID, token) => {
    const result = await KidsApiService.getChannelVideos(channelID,
      localStorage.getItem("kid_id"), token);
    setContinuationToken(result.continuation_token);
    return result.videos;
  }, []);

  const fetchPlaylistVideos = useCallback(async (playlistID, token) => {
    const result = await KidsApiService.getPlaylistVideos(playlistID,
      localStorage.getItem("kid_id"), token);
    setContinuationToken(result.continuation_token);
    return result.videos;
  }, []);

  const fetchMoreVideos = async () => {
    if (continuationToken == null)
      return [];

    setLoadingMoreVideos(true);
    let moreVideos;
    if (channel.id.slice(0, 2) === "PL") {
      // The ID is for a playlist
      moreVideos = videos.concat(await fetchPlaylistVideos(channel.id, continuationToken));
    } else {
      // The ID is for a channel
      moreVideos = videos.concat(await fetchChannelVideos(channel.id, continuationToken));
    }
    setVideos(moreVideos);
    setLoadingMoreVideos(false);
  }

  useEffect(() => {
    const fetchVideos = async () => {
      if (channel.id != null) {
        setIsLoading(true);
        if (channel.id.slice(0, 2) === "PL") {
          // The ID is for a playlist
          setVideos(await fetchPlaylistVideos(channel.id, null));
        } else {
          // The ID is for a channel
          setVideos(await fetchChannelVideos(channel.id, null));
        }
        setIsLoading(false);
      }
    }

    if (shouldLoad) {
      setContinuationToken(null);
      fetchVideos();
    }
  }, [shouldLoad, fetchChannelVideos, channel, fetchPlaylistVideos]);

  useKeyboard(event => {
    if (videos.length > 0)
      return;

    if (event.keyCode === UP_KEY) {
      onMenuSelect();
    }
  }, "SLOW");

  return (
    <>
      {requiresTitle && (
        <div className="shoveler-header">
          <h1>{channel.title}</h1>
        </div>
      )}
      {isLoading || !shouldLoad ? (
        format === "grid" ? (
          <Spinner />
        ) : (
          <LoadingShoveler isSelected={isSelected} />
        )
      ) : (
        videos.length > 0 ? (
          format === "grid" ? (
            <Grid videos={videos} isSelected={isSelected} onMenuSelect={onMenuSelect}
              isVideoVisible={isVideoVisible} onVideoVisibleChange={onVideoVisibleChange}
              loadingMoreVideos={loadingMoreVideos} onFetchMoreVideos={fetchMoreVideos} />
          ) : (
            <Shoveler videos={videos} isSelected={isSelected}
              isVideoVisible={isVideoVisible} onVideoVisibleChange={onVideoVisibleChange}
              loadingMoreVideos={loadingMoreVideos} onFetchMoreVideos={fetchMoreVideos} />
          )
        ) : (
          <div className="no-videos">
            <p>This channel has no videos</p>
          </div>
        )
      )}
    </>
  );
}

export default Channel;
