import React, { useEffect } from "react";
import "./TimeIsUp.css";
import { ReactComponent as ClockIcon } from "./clock.svg";
import QRCode from "react-qr-code";
import { nanoid } from "nanoid";
import * as KidsApiService from './KidsApiService';

let shouldStopWaiting = false;

function TimeIsUp({ onTimeAdded }) {
  const token = nanoid();

  useEffect(() => {
    const waitForAddedTime = async () => {
      if (shouldStopWaiting)
        return;
      const result = await KidsApiService.getAddedTime(localStorage.getItem("kid_id"), token);
      if (result.status !== 204 && result !== "") {
        onTimeAdded(result.minutes_added);
      } else {
        setTimeout(waitForAddedTime, 2000);
      }
    }

    shouldStopWaiting = false;
    waitForAddedTime();
    return () => {
      shouldStopWaiting = true;
    }
  }, [onTimeAdded, token]);

  return (
    <div className="overlay">
      <div className="icon">
        <ClockIcon fill={"white"} />
      </div>
      <h1>Time's up for today!</h1>
      <>
        <p>Please scan this QR code to add more time.</p>
        <QRCode value={`https://parent.safe.vision/kids/${localStorage.getItem("kid_id")}/add_time?token=${token}`} size={400} />
      </>
    </div>
  );
};

export default TimeIsUp;
