import React from "react";
import './LoadingShoveler.css';

function LoadingShoveler({ isSelected }) {  

  return (
    <div className="loading-shoveler-holder">
      <div className={isSelected ? "placeholder selected" : "placeholder"} />
      <div className="placeholder" />
      <div className="placeholder" />
      <div className="placeholder" />
    </div>
  );
}

export default LoadingShoveler;
