import { useEffect, useRef } from "react";
import throttle from "lodash/throttle";

export const ENTER = 13;
export const RIGHT_KEY = 39;
export const LEFT_KEY = 37;
export const UP_KEY = 38;
export const DOWN_KEY = 40;
export const FIRE_TV_PAUSE = 179;
export const FIRE_TV_FORWARD = 228;
export const FIRE_TV_REWIND = 226;

const slowEventHandlers = new Set();
const quickEventHandlers = new Set();

const quickThrottled = throttle((e) => {
  for (const eventHandler of quickEventHandlers) {
    let handled = eventHandler(e);
    if (handled) {
      break;
    }
  }
}, 50);

const slowThrottled = throttle((e) => {
  for (const eventHandler of slowEventHandlers) {
    let handled = eventHandler(e);
    if (handled) {
      break;
    }
  }
}, 400);

const eventListener = (e) => {
  if (e.keyCode === UP_KEY || e.keyCode === DOWN_KEY ||
    e.keyCode === LEFT_KEY || e.keyCode === RIGHT_KEY) {
    e.preventDefault();
  }
  quickThrottled(e);
  slowThrottled(e);
}

window.addEventListener("keydown", eventListener);

export default function useKeyboard(handler, throttleSpeed) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    if (throttleSpeed === "SLOW") {
      slowEventHandlers.delete(savedHandler.current);
      slowEventHandlers.add(handler);
    } else {
      quickEventHandlers.delete(savedHandler.current);
      quickEventHandlers.add(handler);
    }

    savedHandler.current = handler;

    return () => {
      slowEventHandlers.delete(savedHandler.current);
      quickEventHandlers.delete(savedHandler.current);
    }
  }, [handler, throttleSpeed]);
}
