import axios from 'axios';

const BASE_URL = "https://kids-api.safe.vision/api";

export async function getHomeVideos(kid_id) {
  let result = await get(`/kids/${kid_id}/home`);
  return result.data.videos;
}

export async function getChannels(kid_id) {
  let result = await get(`/channels`, { kid_id });
  return result.data;
}

export async function getChannelVideos(channel_id, kid_id, continuation_token) {
  let result = await get(`/channels/${channel_id}/videos2`,
    { kid_id, continuation_token });

  return result.data;
}

export async function getRecentChannels(kid_id) {
  let result = await get(`/kids/${kid_id}/recent_channels`);
  return result.data;
}

export async function getPlaylistVideos(channel_id, kid_id, continuation_token) {
  let result = await get(`/playlists/${channel_id}/videos`,
    { kid_id, continuation_token });

  return result.data;
}

export function trackWatch(kid_id, video_id) {
  axios.post(BASE_URL + "/history", {
    "kid_id": parseInt(kid_id),
    "video_id": video_id
  }, { headers: {
    Authorization: localStorage.getItem("token")
  }});
}

export async function getKid(kid_id) {
  let result = await get(`/kids/${kid_id}`);
  return result.data;
}

export async function getAddedTime(kid_id, token) {
  let result = await get(`/kids/${kid_id}/check_added_time?token=${token}`);
  return result.data;
}

async function get(path, query) {
  return await axios(BASE_URL + path, {
    params: query,
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}
