import React, { useState, useMemo, useEffect } from "react";
import useKeyboard, { ENTER, RIGHT_KEY, LEFT_KEY, UP_KEY, DOWN_KEY } from './use-keyboard';
import './Menu.css';
import { ReactComponent as HomeIcon } from "./home.svg";
import { ReactComponent as SearchIcon } from "./search.svg";

const MENU_ITEM_HEIGHT = 92.66;

function Menu({ channels, isMenuSelected, onDeselect, currentChannel,
  onChannelSelect, onHomeSelect, onSearchSelect, isHome, isMenuOpen, onMenuOpen }) {

  const menuItems = useMemo(() => [
    {
      id: "Home",
      title: "Home",
      image: ""
    },
    ...localStorage.getItem("kid_id") === "11528" ? [{
      id: "Search",
      title: "Search",
      image: ""
    }] : [],
    ...channels
  ], [channels]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevSelectedIndex, setPrevSelectedIndex] = useState(0);
  const [topIndexOnScreen, setTopIndexOnScreen] = useState(0);

  const MAX_MENU_ITEMS_ON_SCREEN = 10;

  useEffect(() => {
    if (selectedIndex - topIndexOnScreen >= MAX_MENU_ITEMS_ON_SCREEN) {
      setTopIndexOnScreen(selectedIndex - MAX_MENU_ITEMS_ON_SCREEN + 1);
    }

    if (selectedIndex < topIndexOnScreen) {
      setTopIndexOnScreen(selectedIndex);
    }

  }, [selectedIndex, topIndexOnScreen]);

  useKeyboard(event => {
    if (!isMenuSelected)
      return false;

    if (!isMenuOpen) {
      if (event.keyCode === ENTER) {
        onMenuOpen(true);
      } else if (event.keyCode === RIGHT_KEY || event.keyCode === LEFT_KEY ||
        event.keyCode === DOWN_KEY) {
        onDeselect();
      }
      return true;
    }

    if (event.keyCode === LEFT_KEY) {
      onDeselect();
      return true;
    }

    if (event.keyCode === UP_KEY) {
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
      return true;
    }

    if (event.keyCode === DOWN_KEY) {
      if (selectedIndex < menuItems.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
      return true;
    }

    if (event.keyCode === ENTER || event.keyCode === RIGHT_KEY) {
      if (menuItems[selectedIndex] !== currentChannel) {
        if (menuItems[selectedIndex].id === "Home") {
          if (!isHome) {
            // Only select home if we aren't already home
            onHomeSelect();
            setPrevSelectedIndex(selectedIndex);
          }
        } else if (menuItems[selectedIndex].id === "Search") {
          onSearchSelect();
          setPrevSelectedIndex(selectedIndex);
        } else {
          onChannelSelect(menuItems[selectedIndex]);
          setPrevSelectedIndex(selectedIndex);
        }
      }
      onDeselect();
      return true;
    }

    return false;
  }, "FAST");

  function getTranslateY() {
    if (isMenuOpen)
      return -MENU_ITEM_HEIGHT * topIndexOnScreen;
    else
      return -MENU_ITEM_HEIGHT * prevSelectedIndex;

  }

  function getItemClassNames(index) {
    let classNames = "leftnav-list-item-static";
    if (isMenuOpen) {
      if (selectedIndex === index) {
        classNames += " leftnav-list-item-selected";
      }
    } else {
      if (isMenuSelected) {
        classNames += " leftnav-list-item-highlighted";
      } else if (index === prevSelectedIndex) {
        classNames += " leftnav-list-item-chosen";
      }
    }
    return classNames;
  }

  function getIconClassName(id) {
    let className = id.slice(0, 2) === "PL" ? "playlist-icon" : "channel-icon";
    return className + " title-icon";
  }

  return (
    <>
      <div id="app-header-bar" className="app-top-bar">
        <div id="left-nav" className="leftnav-main-container"
          style={{ position: isMenuOpen ? "absolute" : "relative" }}>
          <div id="left-nav-list-container" className={`leftnav-menulist-${isMenuOpen ? "expanded" : "collapsed"} 
          ${(isMenuSelected && !isMenuOpen) ? "leftnav-collapsed-highlight" : ""}
          ${isMenuOpen ? "leftnav-collapsed-selected" : ""}`}>
            <div id="left-nav-scrolling-list" className="leftnav-menu-scrolling-list"
              style={{ transform: `translateY(${getTranslateY()}px)` }}>
              {menuItems.map((menuItem, index) =>
                <div key={menuItem.id} className="leftnav-list-item-container">
                  <div className={getItemClassNames(index)}>
                    <div className="leftnav-list-item">
                      {menuItem.id === "Home" || menuItem.id === "Search" ?
                        menuItem.id === "Home" ? (
                          <div className={"title-icon"}>
                            <HomeIcon fill={menuItems[selectedIndex].id === "Home" && isMenuOpen ?
                              "black" : "white"} />
                          </div>
                        ) : (
                          <div className={"title-icon"}>
                            <SearchIcon fill={menuItems[selectedIndex].id === "Search" && isMenuOpen ?
                              "black" : "white"} />
                          </div>
                        )
                        :
                        <img src={menuItem.image} alt={menuItem.title}
                          className={getIconClassName(menuItem.id)} />
                      }
                      {menuItem.title}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu;
