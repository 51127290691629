import React, { useState, useEffect, useRef } from "react";
import Channel from "./Channel";
import Home from "./Home";
import Search from "./Search";
import SignIn from "./SignIn";
import * as KidsApiService from './KidsApiService';
import './App.css';
import Menu from "./Menu";

function App() {
  const [channels, setChannels] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [currentScreen, setCurrentScreen] = useState('home');
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [isMenuSelected, setMenuSelected] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [signedIn, setSignedIn] = useState(
    localStorage.getItem("kid_id") && localStorage.getItem("token"));

  const menuRef = useRef();

  useEffect(() => {
    const storedDate = localStorage.getItem("storedDate");
    let currentTime = new Date();
    const currentDate = currentTime.getFullYear() + '-' +
      String(currentTime.getMonth() + 1).padStart(2, '0') +
      '-' + String(currentTime.getDate()).padStart(2, '0');

    if (storedDate !== currentDate) {
      localStorage.setItem("dailyElapsedTime", 0);
      localStorage.setItem("storedDate", currentDate);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      fetchChannels();
    }

    if (signedIn) {
      fetchData();
    }
  }, [signedIn]);

  function handleChannelSelect(channel) {
    setCurrentChannel(channel);
    setCurrentScreen('channel');
  }

  function handleHomeSelect() {
    setCurrentScreen('home');
    setCurrentChannel(null);
  }

  function handleSearchSelect() {
    setCurrentScreen('search');
    setCurrentChannel(null);
  }

  function handleMenuSelect() {
    setMenuSelected(true);
  }

  const fetchChannels = async () => {
    const chs = await KidsApiService.getChannels(localStorage.getItem("kid_id"));
    setChannels(chs);
  }

  function handleDeselect() {
    setMenuOpen(false);
    setMenuSelected(false);
  }

  function scrollMenuIntoView() {
    if (menuRef != null) {
      menuRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  function renderScreen() {
    switch (currentScreen) {
      case 'home':
        return <Home
          isSelected={!isMenuSelected && !isMenuOpen}
          isVideoVisible={isVideoVisible} onRefresh={fetchChannels}
          onVideoVisibleChange={visible => setVideoVisible(visible)}
          onMenuSelect={handleMenuSelect} scrollToTop={scrollMenuIntoView}
        />;
      case 'channel':
        return <Channel channel={currentChannel}
          isSelected={!isMenuSelected && !isMenuOpen}
          shouldLoad={true} requiresTitle={false} onMenuSelect={handleMenuSelect}
          isVideoVisible={isVideoVisible} format={"grid"}
          onVideoVisibleChange={visible => setVideoVisible(visible)}
        />;
      case 'search':
        return <Search isSelected={!isMenuSelected && !isMenuOpen}
          shouldLoad={true} onMenuSelect={handleMenuSelect}
          isVideoVisible={isVideoVisible}
          onVideoVisibleChange={visible => setVideoVisible(visible)} />;
      default:
        return <div></div>;
    }
  }

  if (!signedIn) {
    return <SignIn onSignIn={() => setSignedIn(true)} />;
  }
  return (
    <div className="app" id="app">
      <div ref={menuRef}>
        <Menu
          channels={channels} onChannelSelect={channel => handleChannelSelect(channel)}
          isMenuSelected={isMenuSelected} onDeselect={handleDeselect}
          isMenuOpen={isMenuOpen} onMenuOpen={(e) => setMenuOpen(e)} appState={currentScreen}
          onHomeSelect={handleHomeSelect} currentChannel={currentChannel}
          onSearchSelect={handleSearchSelect} />
      </div>
      {
        renderScreen()
      }
    </div>
  );
}

export default App;
