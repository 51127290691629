import YouTube from "react-youtube";
import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import useKeyboard, { ENTER, RIGHT_KEY, LEFT_KEY, FIRE_TV_FORWARD, FIRE_TV_PAUSE, FIRE_TV_REWIND } from './use-keyboard';
import PlayerControls from "./PlayerControls";
import Spinner from "./Spinner";
import "./YouTubeVideo.css";
import * as KidsApiService from './KidsApiService';
import TimeIsUp from "./TimeIsUp";
import { ReactComponent as ErrorIcon } from "./error.svg";

const videoRoot = document.getElementById('video-root');

let secondsWatchedThisVideo = 0;
let totalSecondsWatched = 0;
let intervalID = null;
let timeoutID = null;
let addedToHistory = false;
let timeLimit = 0;

const MAX_LOADING_TIME = 25; // Measured in seconds
const ELAPSED_TIME_SAVE_INTERVAL = 60; // Measured in seconds
const HISTORY_MIN_TIME = 60; // Measured in seconds
const ENDED = 0;
const PLAYING = 1;
const PAUSED = 2;

function YouTubeVideo({ video }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [player, setPlayer] = useState(null);
  const [paused, setPaused] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isTimeUp, setTimeUp] = useState(false);

  useEffect(() => {
    addedToHistory = false;

    const fetchDailyTimeLimit = async () => {
      const kid = await KidsApiService.getKid(localStorage.getItem("kid_id"));
      return kid.daily_time_limit;
    }

    fetchDailyTimeLimit().then(limit => {
      if (!timeLimit) {
        timeLimit = limit;
      }

      if (localStorage.getItem("dailyElapsedTime") >= timeLimit) {
        setTimeUp(true);
      }
    });
  }, []);

  useEffect(() => {
    timeoutID = setTimeout(() => {
      setVideoLoaded(true);
    }, 1000 * MAX_LOADING_TIME);

    return () => {
      clearTimeout(timeoutID);
      timeoutID = null;
    }
  }, []);

  useEffect(() => {
    if (intervalID !== null || !player || isTimeUp)
      return;

    secondsWatchedThisVideo = 0;
    intervalID = setInterval(() => {
      if (player.getPlayerState() !== PLAYING)
        return;
      secondsWatchedThisVideo++;
      totalSecondsWatched++;
      setCurrentTime(player.getCurrentTime());

      if (secondsWatchedThisVideo >= HISTORY_MIN_TIME && !addedToHistory) {
        KidsApiService.trackWatch(localStorage.getItem("kid_id"), video.id);
        addedToHistory = true;
      }

      if (totalSecondsWatched % ELAPSED_TIME_SAVE_INTERVAL === 0) {
        const prevTime = localStorage.getItem("dailyElapsedTime");
        const newTime = Number(prevTime) + ELAPSED_TIME_SAVE_INTERVAL / 60;
        localStorage.setItem("dailyElapsedTime", newTime);

        if (newTime >= timeLimit) {
          setTimeUp(true);
          clearInterval(intervalID);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalID);
      intervalID = null;
    }
  }, [video, player, isTimeUp]);

  function handleVideoReady(event) {
    event.target.playVideo();
    setPlayer(event.target);
  }

  useKeyboard(event => {
    if (!videoLoaded || event.repeat)
      return;

    if (event.keyCode === ENTER || event.keyCode === FIRE_TV_PAUSE) {
      if (player.getPlayerState() === ENDED) {
        player.seekTo(0, true);
        setCurrentTime(0);
        player.playVideo();
        setPaused(false);
      } else if (player.getPlayerState() === PAUSED) {
        player.playVideo();
        setCurrentTime(player.getCurrentTime());
      } else if (player.getPlayerState() === PLAYING) {
        player.pauseVideo();
        setCurrentTime(player.getCurrentTime());
      }
    }

    if (
      event.keyCode === RIGHT_KEY || event.keyCode === LEFT_KEY ||
      event.keyCode === FIRE_TV_REWIND || event.keyCode === FIRE_TV_FORWARD
    ) {
      let newTime = currentTime +
        (event.keyCode === RIGHT_KEY || event.keyCode === FIRE_TV_FORWARD ? 30 : -30);
      if (newTime < 0) {
        newTime = 0;
      } else if (newTime > player.getDuration()) {
        newTime = player.getDuration();
        setPaused(false);
      }
      player.seekTo(newTime, true);
      setCurrentTime(newTime);
    }
  }, "FAST");

  function handlePause() {
    setPaused(true);
  }

  function handlePlay() {
    setVideoLoaded(true);
    setPaused(false);
  }

  function handleTimeAdded(minutesAdded) {
    if (localStorage.getItem("kid_id") === "11528" && minutesAdded > 15) {
      timeLimit = parseInt(localStorage.getItem("dailyElapsedTime")) + 1;
    } else {
      timeLimit = parseInt(localStorage.getItem("dailyElapsedTime")) + minutesAdded;
    }
    setTimeUp(false);
  }

  function handleError(event) {
    setVideoLoaded(true);
    if (event.message) {
      setErrorMessage(event.message);
    }
  }

  if (isTimeUp) {
    return <TimeIsUp onTimeAdded={handleTimeAdded} />;
  }
  return ReactDOM.createPortal((
    <div className="video-overlay">
      {!videoLoaded &&
        <div className="overlay-holder">
          <Spinner />
        </div>
      }
      {errorMessage &&
        <div className="overlay-holder">
          <ErrorIcon fill={"#D3D3D3"} />
          <h1 className="error-message">{errorMessage}</h1>
        </div>
      }
      <YouTube
        id="youtube-player"
        videoId={video.id}
        opts={{
          width: "1920",
          height: "1080",
          playerVars: {
            enablejsapi: 1,
            fs: 0, // disable fullscreen button
            showinfo: 0,
            vq: "hd1080",
            controls: "0"
          }
        }}
        onReady={handleVideoReady}
        onPause={handlePause}
        onPlay={handlePlay}
        onError={handleError}
      />
      {localStorage.getItem("kid_id") === "11528" && <div className="timer">{`${timeLimit}/${totalSecondsWatched}/${secondsWatchedThisVideo}`}</div>}
      {paused && videoLoaded && player && <PlayerControls duration={player.getDuration()}
        currentTime={currentTime} videoTitle={video.title} />}
    </div>
  ),
    videoRoot
  );
}

export default YouTubeVideo;