import React, { useEffect, useState, useRef } from 'react';
import Grid from "./Grid";
import Spinner from "./Spinner";
import useKeyboard, { UP_KEY, DOWN_KEY } from './use-keyboard';
import "./Search.css";

function Search({ isSelected, shouldLoad, isVideoVisible,
  onVideoVisibleChange, onMenuSelect }) {

  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [isInputSelected, setInputSelected] = useState(true);

  const searchRef = useRef();

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoading(true);
      setVideos([
        { id: 'AxLuEi3Eplo', title: 'How To Draw A Funny Christmas Cheeseburger' },
        { id: 'qTkBCNnXgJw', title: 'Funny Christmas Tree Cutout' },
        { id: 'MaoRDk0nXZM', title: 'Draw Your Own Christmas Tree Decoration Challenge!' },
        { id: '49zsssZWcCg', title: 'Drawing a Christmas tree getting dressed for the holidays 🤣 #artforkidshub ❤️💛💙' },
        { id: 'xgWR6r3_lRo', title: 'How To Draw A Spinosaurus Dinosaur' }
      ]);
      setIsLoading(false);
    }

    if (shouldLoad) {
      fetchVideos();
    }
  }, [shouldLoad]);

  useEffect(() => {
    if (isInputSelected) {
      searchRef.current.focus();
    } else {
      searchRef.current.blur();
    }
  }, [isInputSelected]);

  const fetchMoreVideos = async () => {

  }

  useKeyboard(event => {
    if (!isInputSelected)
      return;

    if (event.keyCode === UP_KEY) {
      onMenuSelect();
      setInputSelected(false);
    }

    if (event.keyCode === DOWN_KEY) {
      setInputSelected(false);
    }
  }, "SLOW");

  function handleSearchSelection() {
    setInputSelected(true);
  }

  return (
    <>
      <div className="search">
        <input ref={searchRef} type="text" placeholder="Search" />
      </div>
      {isLoading || !shouldLoad ? (
        <Spinner />
      ) : (
        videos.length > 0 ? (
          <Grid videos={videos} isSelected={isSelected && !isInputSelected} onMenuSelect={handleSearchSelection}
            isVideoVisible={isVideoVisible} onVideoVisibleChange={onVideoVisibleChange}
            loadingMoreVideos={false} onFetchMoreVideos={fetchMoreVideos} />
        ) : (
          <div className="no-videos">
            <p>No search results found.</p>
          </div>
        )
      )}
    </>
  );
}

export default Search;
