import './Spinner.css';

function Spinner() {

  return (
    <div className="background">
      <div className="spinner" />
    </div>
  );
}

export default Spinner;
