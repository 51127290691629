import React, { useEffect, useState, useRef } from 'react';
import LoadingShoveler from "./LoadingShoveler";
import useKeyboard, { ENTER, UP_KEY, DOWN_KEY } from './use-keyboard';
import * as KidsApiService from './KidsApiService';
import Shoveler from "./Shoveler";
import Channel from "./Channel";
import Spinner from "./Spinner";
import "./Home.css";

function Home({ isSelected, isVideoVisible, onVideoVisibleChange, onRefresh, onMenuSelect, scrollToTop }) {

  const [isLoading, setIsLoading] = useState(false);
  const [recentChannels, setRecentChannels] = useState([]);
  const [videos, setVideos] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [recentChannelsLoaded, setRecentChannelsLoaded] = useState(false);
  const [shovelerIndex, setShovelerIndex] = useState(0);
  const [maxShovelerIndex, setMaxShovelerIndex] = useState(0);

  const channelsRef = useRef([]);

  useEffect(() => {
    fetchChannels();
  }, []);

  useEffect(() => {
    channelsRef.current = channelsRef.current.slice(0, recentChannels.length);
  }, [recentChannels]);

  const fetchChannels = async () => {
    setRecentChannelsLoaded(false);
    const recentChs = await KidsApiService.getRecentChannels(localStorage.getItem("kid_id"));
    setRecentChannels(recentChs);
    setRecentChannelsLoaded(true);
  }

  const fetchHomeVideos = async () => {
    setIsLoading(true);
    const videos = await KidsApiService.getHomeVideos(localStorage.getItem("kid_id"));
    setVideos(videos);
    setIsLoading(false);
  }

  useEffect(() => {
    if (!hasLoaded) {
      fetchHomeVideos();
      setHasLoaded(true);
    }
  }, [hasLoaded]);

  useKeyboard(event => {
    const refresh = async () => {
      await fetchHomeVideos();
      onRefresh();
    }

    if (!isSelected || isVideoVisible || videos.length <= 0)
      return;

    if (event.keyCode === ENTER && videos.length <= 0) {
      refresh();
    }

    let newIndex = shovelerIndex;
    if (event.keyCode === DOWN_KEY) {
      newIndex = shovelerIndex + 1;
    } else if (event.keyCode === UP_KEY) {
      newIndex = shovelerIndex - 1;
    } else {
      return false;
    }

    if (newIndex < 0) {
      // Select menu
      onMenuSelect();
      return true;
    }

    if (newIndex > recentChannels.length) {
      return false;
    }

    setShovelerIndex(newIndex);
    setMaxShovelerIndex(Math.max(newIndex, maxShovelerIndex));
    if (newIndex === 0) {
      // Scroll to top
      scrollToTop();
    } else {
      // Multiple recent channels
      channelsRef.current[newIndex - 1].scrollIntoView({ behavior: "smooth" });
    }

    return true;
  }, "SLOW");

  return (
    <>
      {isLoading || !hasLoaded ? (
        <LoadingShoveler isSelected={isSelected} />
      ) : (
        videos.length > 0 ? (
          <>
            <Shoveler videos={videos} isSelected={isSelected && shovelerIndex === 0}
              isVideoVisible={isVideoVisible} onVideoVisibleChange={onVideoVisibleChange}
              loadingMoreVideos={false} onFetchMoreVideos={() => { }} />

            {recentChannels.length > 0 ? (
              recentChannels.map((channel, index) =>
                <React.Fragment key={index}>
                  <div ref={element => channelsRef.current[index] = element}>
                    <Channel channel={channel}
                      isSelected={shovelerIndex === index + 1}
                      shouldLoad={maxShovelerIndex >= index}
                      isVideoVisible={isVideoVisible} requiresTitle={true}
                      onVideoVisibleChange={onVideoVisibleChange}
                      format={"shoveler"}
                    />
                  </div>
                </React.Fragment>
              )) : (
              !recentChannelsLoaded &&
              (
                <div className="app-spinner">
                  <Spinner />
                </div>
              )
            )}
          </>
        ) : (
          <div className="no-videos">
            <p>Please install the Safe Vision App on your phone to add videos for your child.</p>
            <p>Press the button below once you have added videos.</p>
            <button id="next-button" className="next-button">Next</button>
          </div>
        )
      )}
    </>
  );
}

export default Home;
