import history from "history/browser";
import React, { useEffect, useRef, useState } from "react";
import useKeyboard, { ENTER, RIGHT_KEY, LEFT_KEY } from './use-keyboard';
import './Shoveler.css';
import Spinner from "./Spinner";
import YouTubeVideo from "./YouTubeVideo";

function Shoveler({ videos, isSelected, onVideoVisibleChange,
  isVideoVisible, loadingMoreVideos, onFetchMoreVideos }) {

  const [leftmostIndexOnScreen, setLeftmostIndexOnScreen] = useState(0);
  const [videoIndex, setVideoIndex] = useState(0);

  const thumbnailHolderRef = useRef(null);

  const MAX_VIDEOS_ON_SCREEN = 3;

  useEffect(() => {
    if (videoIndex - leftmostIndexOnScreen >= MAX_VIDEOS_ON_SCREEN) {
      setLeftmostIndexOnScreen(videoIndex - MAX_VIDEOS_ON_SCREEN + 1);
      if (thumbnailHolderRef.current != null) {
        const thumbnail = thumbnailHolderRef.current.querySelector(
          `div:nth-child(${videoIndex + 1})`);
        thumbnailHolderRef.current.scrollLeft = thumbnail.offsetLeft - 1185;
      }
    }

    if (videoIndex < leftmostIndexOnScreen) {
      setLeftmostIndexOnScreen(videoIndex);
      if (thumbnailHolderRef.current != null) {
        const thumbnail = thumbnailHolderRef.current.querySelector(
          `div:nth-child(${videoIndex + 1})`);
        thumbnailHolderRef.current.scrollLeft = thumbnail.offsetLeft - 150;
      }
    }

  }, [videoIndex, leftmostIndexOnScreen]);

  useEffect(() => {
    history.listen(({ location, action }) => {
      if (location.pathname === "/" && action === "POP") {
        onVideoVisibleChange(false);
      }
    });
  }, [onVideoVisibleChange]);

  useKeyboard(event => {
    if (!isSelected || isVideoVisible)
      return;

    let direction = 0;
    if (event.keyCode === RIGHT_KEY || event.keyCode === LEFT_KEY) {
      if (event.keyCode === RIGHT_KEY) {
        direction = 1; // Going right
      } else {
        direction = -1; // Going left
      }
      if ((direction > 0 && videoIndex >= videos.length - 1) ||
        (direction < 0 && videoIndex <= 0)) {
        // Do nothing as we're going out of bounds
      } else {
        setVideoIndex(videoIndex + direction);
      }
    }

    if (event.keyCode === ENTER) {
      onVideoVisibleChange(true);
      history.push("/video");
    }

    // Check if more videos need to be loaded based on videoIndex
    if (videoIndex === videos.length - 5) {
      onFetchMoreVideos();
    }

  }, "SLOW");

  return (
    <>
      <div className="channel" tabIndex="0">
        <div id="one-D-view-item-elements" className="one-D-details-container">
          <div id="one-d-no-items" className="one-d-no-items-container">No content found.</div>
          <div id="one-D-shoveler-container">
            <div id="channel-view" className="channel-row-container">
              {<div className="thumbnail-holder"
                ref={thumbnailHolderRef}>
                {videos.map((video, index) =>
                  <div key={video.id}
                    className={"shoveler-rowitem-static" +
                      (isSelected && index === videoIndex ? " selected" : "")}>
                    <img className="shoveler-full-img"
                      src={`https://i.ytimg.com/vi/${video.id}/hqdefault.jpg`}
                      alt={video.title}
                    />
                    <p className="video-title">
                      {videos[index].title}
                    </p>
                  </div>
                )}
                {loadingMoreVideos &&
                  <div className="channel-spinner">
                    <Spinner />
                  </div>
                }
              </div>}
            </div>
          </div>
        </div>
      </div>
      {isVideoVisible && isSelected && <YouTubeVideo video={videos[videoIndex]} />}
    </>
  );
}

export default Shoveler;
