import React from "react";
import './PlayerControls.css';

function PlayerControls({ duration, currentTime, videoTitle }) {

  function convertSecondsToHHMMSS(seconds, alwaysIncludeHours) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor(seconds / 60) % 60;
    seconds = Math.floor(seconds % 60);

    var finalString = "";

    if (hours > 0 || alwaysIncludeHours) {
      finalString += hours + ":";
    }
    return (
      finalString +
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + seconds).slice(-2)
    );
  }

  function getTimePercentage(videoDuration, videoCurrentTime) {
    return (100 / videoDuration) * videoCurrentTime + "%";
  }

  return (
    <div id="player-controls-container">
      <div id="timeline-container">
        <div id="timeline">
          <div id="timeline-playhead"
            style={{ width: getTimePercentage(duration, currentTime) }}
          />
        </div>
      </div>
      <div id="current-timestamp">
        {convertSecondsToHHMMSS(currentTime)}
      </div>
      <div id="total-timestamp">
        {convertSecondsToHHMMSS(duration)}
      </div>
      <div id="video-title">{videoTitle}</div>
    </div>
  );
}

export default PlayerControls;
