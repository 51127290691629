import axios from "axios";
import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import QRCode from "react-qr-code";
import './SignIn.css';

const token = nanoid();

function SignIn({ onSignIn }) {
  const [error, setError] = useState(null);

  useEffect(() => {
    const pollServer = () => {
      axios("https://parent.safe.vision/api/firetv/link?token=" + token)
      .then((result) => {
        setError(null);
        if (result.status === 204) {
          setTimeout(pollServer, 2000);
        } else {
          handleLinked(result);
        }
      }).catch((error) => {
        setError(error);
        setTimeout(pollServer, 2000);
      });
    }

    const handleLinked = (event) => {
      localStorage.setItem("kid_id", event.data.kid_id);
      localStorage.setItem("token", event.data.token);
      onSignIn();
    }

    pollServer();
  }, [onSignIn]);

  return (
    <div className="signin">
      <h1>Welcome to Safe Vision</h1>
      {error ? (
        <>
          <h2>Oops, an error occured</h2>
          <p>{error.message}</p>
          <h3>Trying to reconnect...</h3>
          <p>If the issue persists contact support</p>
        </>
      ) : (
        <>
          <h3>Please scan this QR code to sign into your account</h3>
          <QRCode value={`https://parent.safe.vision/firetv?token=${token}`} size={400} />
          <p>This screen will refresh when completed</p>
        </>
      )}
    </div>
  );
}

export default SignIn;
